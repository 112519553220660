import * as React from "react";
import { Link } from "gatsby";

export const Footer = ()  => {
    return (
        <div className="text-white bg-black border-t border-gray-200 px-4 py-2 flex justify-between">
            <div>{new Date().getFullYear()} &copy; Premium Cars Berbati &amp; Hammes GmbH</div>
            <div>
                <Link to="/impressum">Impressum &amp; Datenschutz</Link>
            </div>
        </div>
    )
}
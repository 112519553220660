import * as React from "react";

interface IWrapper {
    children: any;
}

export const Wrapper: React.FC<IWrapper> = ({ children }) => {
    return (
        <div className="bg-black p-4 md:p-32 min-h-screen text-gray-200">
            {children}
        </div>
    )
}
import * as React from "react"
import  { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Footer } from "../components";
import Logo from "../images/logo.svg";

// markup
const IndexPage = () => {
  return (
    <>
      <main className="min-h-screen bg-black md:flex">
        <div className="md:w-1/3 md:h-screen flex items-center justify-center">
          <img src={Logo} alt="Logo Premium Cars Falkensee" className="p-12" />
        </div>
        <div className="md:w-2/3 md:h-screen block">
          <Link href="https://home.mobile.de/PREMIUMCARSBERLIN" className="block" target="_blank" rel="noopener noreferrer">
            <div className={"relative"}>
              <div>
                <StaticImage
                  src="../images/car-2021-08-26-13-40-13-utc.jpg"
                  alt=""
                  placeholder="blurred"
                  className="object-cover object-left md:h-screen"
                />
              </div>
              <div className="p-4 md:p-12 absolute top-0 left-0 h-full w-full flex items-end">
                <span className="text-3xl bg-black text-white font-semibold p-6 rounded">Fahrzeuge</span>
              </div>
            </div>
          </Link>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
